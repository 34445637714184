<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-8">
                <form class="form-inline">
                  <div class="form-group">
                    <label class="sr-only">Search</label>
                    <input
                      type="search"
                      class="form-control"
                      placeholder="Search..."
                      @input="searchAdmin"
                      v-model="search"
                    />
                  </div>
                </form>
              </div>
              <div class="col-lg-4">
                <div class="text-lg-right mt-3 mt-lg-0">
                  <a
                    class="btn text-white btn-danger"
                    href="javascript: void(0);"
                    @click="addNewAdmin"
                  >
                    <i class="mdi mdi-plus-circle mr-1"></i> Add New Admin
                  </a>
                </div>
              </div>
              <!-- end col-->
            </div>
            <!-- end row -->
          </div>
          <!-- end card-box -->
        </div>
      </div>
      <!-- end col-->
    </div>
    <div class="row">
      <div v-for="(admin, i) in admins" :key="i" class="col-xl-4 col-sm-6">
        <div class="card">
          <div class="card-body">
            <div class="media">
              <!--              <div class="avatar-md mr-3">-->
              <!--                <div class="avatar-title bg-light rounded-circle">-->
              <!--                  <img-->
              <!--                      :src="`${admin.logo}`"-->
              <!--                      alt="logo"-->
              <!--                      class="avatar-sm rounded-circle"-->
              <!--                  />-->
              <!--                </div>-->
              <!--              </div>-->
              <div class="media-body">
                <h4 class="my-1">
                  <a href="javascript:void(0);" class="text-dark"
                    >{{ admin.name }}
                    <small
                      ><span v-if="admin['roles'].length > 0"
                        >({{ admin["roles"][0]["name"] }})</span
                      ></small
                    ></a
                  >
                </h4>
                <div class="row">
                  <div class="col-md-7">
                    <div>
                      <i class="ri-map-pin-line align-bottom mr-1"></i>
                      {{ admin.email }}
                    </div>
                  </div>
                  <div class="col-md-5" v-if="admin.phone">
                    <div>
                      <i class="ri-phone-line align-bottom mr-1"></i>
                      {{ admin.phone }}
                    </div>
                  </div>
                </div>
              </div>

              <b-dropdown right toggle-class="text-body p-0" variant="black">
                <template v-slot:button-content>
                  <i class="mdi mdi-dots-vertical font-20"></i>
                </template>
                <b-dropdown-item @click="editAdminUser(admin)"
                  >Edit Admin</b-dropdown-item
                >
                <b-dropdown-item
                  @click="resendPasswordAdminUser(admin)"
                  class="text-danger"
                  >Resend Password</b-dropdown-item
                >

                <b-dropdown-item
                  @click="deleteAdminUser(admin)"
                  class="text-danger"
                  >Delete Admin</b-dropdown-item
                >
              </b-dropdown>
            </div>
            <hr v-if="admin['permissions'].length > 0" />
            <div class="text-muted">
              <div class="row">
                <div class="col-12">
                  <div>
                    <p class="text-truncate mb-0">Permissions</p>
                    <span
                      class="mb-sm-0 badge badge-info mr-1"
                      v-for="(permission, a) in admin['permissions']"
                      :key="'permission_' + a"
                      >{{ permission.name }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="modal-1"
      v-model="showmodal"
      :title="add_modal ? 'Add New Admin' : 'Edit ' + admin_user.name"
      header-close-variant="light"
      title-class="font-18"
      hide-footer
    >
      <form @submit.prevent="saveAdminUser">
        <div class="form-group">
          <label for="name">Name</label>
          <input
            id="name"
            v-model="admin_user.name"
            type="text"
            class="form-control"
            placeholder="Enter name"
            :class="{ 'is-invalid': submitted && $v.admin_user.name.$error }"
          />
          <div
            v-if="submitted && !$v.admin_user.name.required"
            class="invalid-feedback"
          >
            Name is required.
          </div>
        </div>
        <div class="form-group">
          <label for="email">Email</label>
          <input
            id="email"
            :disabled="edit_modal"
            v-model="admin_user.email"
            type="email"
            class="form-control"
            placeholder="Enter email"
            :class="{ 'is-invalid': submitted && $v.admin_user.email.$error }"
          />
          <div
            v-if="submitted && !$v.admin_user.email.required"
            class="invalid-feedback"
          >
            Email is required.
          </div>
        </div>
        <div class="form-group">
          <label for="Phone">Phone</label>
          <input
            id="Phone"
            v-model="admin_user.phone"
            type="text"
            class="form-control"
            placeholder="Enter phone"
          />
        </div>
        <div class="form-group">
          <label>Select permissions</label>
          <b-form-checkbox
            class="mb-2"
            v-model="select_all"
            @change="selectAllPermissions"
          >
            Select All Permissions
          </b-form-checkbox>
          <b-form-checkbox
            class="mb-2"
            :id="'checkbox-' + i"
            v-for="(p, i) in permissions"
            :key="i"
            v-model="admin_user.permissions"
            :value="p.name"
          >
            {{ p.name }}
          </b-form-checkbox>
        </div>

        <div class="text-right">
          <button type="submit" class="btn btn-success">Save</button>
          <b-button class="ml-1" variant="danger" @click="hideModal"
            >Cancel
          </b-button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import appConfig from "../../../../../app.config.json";
import { companies } from "./data";
import { email, required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";

/**
 * Starter component
 */
export default {
  head() {
    return {
      title: `${this.title}`,
    };
  },
  validations: {
    admin_user: {
      name: {
        required,
      },
      email: {
        required,
        email,
      },
    },
  },
  data() {
    return {
      title: "Starter",
      companies: companies,
      showmodal: false,
      submitted: false,
      edit_modal: false,
      add_modal: false,
      edit_admin_data: {},
      admins: [],
      all_admin_data: [],
      permissions: [],
      select_all: false,
      search: "",
      admin_user: {
        id: "",
        name: "",
        email: "",
        phone: "",
        permissions: [],
      },
    };
  },
  methods: {
    /**
     * Modal hide on close
     */
    searchAdmin() {
      if (this.search.length > 0) {
        this.item = [];
        this.item = this.all_admin_data.filter((e) => {
          console.log(e);
          return (
            e.name.toLowerCase().includes(this.search.toLowerCase()) ||
            e.phone.includes(this.search) ||
            e.email.toLowerCase().includes(this.search.toLowerCase())
          );
        });
        this.admins = this.item;
      } else {
        this.admins = this.all_admin_data;
      }
    },
    selectAllPermissions() {
      if (this.select_all) {
        this.admin_user.permissions = this.permissions.map((e) => e.name);
      } else {
        this.admin_user.permissions = [];
      }
    },
    hideModal() {
      this.submitted = false;
      this.showmodal = false;
    },

    deleteAdminUser(admin) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("deleteAdmin", admin.id).then((res) => {
            this.getAdminUserList();
            this.hideModal();
          });
        }
      });
    },
    addNewAdmin() {
      this.edit_modal = false;
      this.add_modal = true;
      this.showmodal = true;
      this.admin_user = {
        id: "",
        name: "",
        email: "",
        phone: "",
        permissions: [],
      };
    },
    resendPasswordAdminUser(admin) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, send it!",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("resendPasswordAdmin", admin.id);
        }
      });
    },

    editAdminUser(admin) {
      this.edit_modal = true;
      this.add_modal = false;
      this.submitted = false;

      this.admin_user.id = admin.id;
      this.admin_user.email = admin.email;
      this.admin_user.name = admin.name;
      this.admin_user.phone = admin.phone;
      let permissions = admin.permissions.map((e) => e.name);
      this.admin_user.permissions = permissions;
      this.showmodal = true;
    },
    saveAdminUser() {
      // stop here if form is invalid
      this.$v.$touch();

      if (!this.$v.$invalid) {
        if (this.admin_user.name && this.admin_user.email) {
          this.hideModal();

          if (this.add_modal) {
            this.$store.dispatch("addAdmin", this.admin_user).then((res) => {
              this.getAdminUserList();
              this.admin_user = {
                id: "",
                name: "",
                email: "",
                phone: "",
                permissions: [],
              };
            });
          } else if (this.edit_modal) {
            this.$store.dispatch("editAdmin", this.admin_user).then((res) => {
              this.getAdminUserList();
              this.admin_user = {
                id: "",
                name: "",
                email: "",
                phone: "",
                permissions: [],
              };
            });
          }
          // this.$store.dispatch("clear");
        }
      }
    },
    getAdminUserList() {
      this.$store.dispatch("getAdmins").then((res) => {
        this.admins = res.extra;
        this.all_admin_data = res.extra;
      });
    },
  },
  created() {
    this.$store.dispatch("getAdminPermissions").then((res) => {
      this.permissions = res.extra;
    });
    this.getAdminUserList();
  },
};
</script>
